/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

interface SecumailProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Misc
}

const Secumail: React.FC<SecumailProps> = () => {
  useEffect(() => {
    // Ensure this code runs only on the client
    if (typeof window !== 'undefined') {
      const urlParams = new URLSearchParams(window.location.search)
      urlParams.set('function', 'secufiler')

      // Update the URL without reloading the page
      window.history.pushState(
        {},
        '',
        `${window.location.pathname}?${urlParams.toString()}`
      )
    }
  }, [])

  useEffect(() => {
    // Load the external script
    const script = document.createElement('script')
    script.src = 'https://portal.secumail.nl/v6/assets/js/portal-jquery.min.js'
    script.async = true
    document.body.appendChild(script)

    return () => {
      // Cleanup: remove the portal div and script when the component is unmounted
      document.body.removeChild(script)
    }
  }, [])

  return (
    <section>
      <div className="container py-5">
        <div className="row py-3 py-lg-5">
          <div className="col-12">
            <div id="portal" />
            <Helmet>
              <script>
                {`
                const portalId = 'portal'
                `}
              </script>
            </Helmet>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Secumail
